<template>
  <div class="MyAttention">
    <div class="box">
      <!-- 主体 -->
      <div class="main">
        <div class="title">
          {{ type == 1 ? "我的关注" : "我的粉丝" }}
        </div>
        <!-- 搜索 -->
        <!-- <div class="search_box">
          <div class="search">
            <input
              class="inp"
              v-model="input"
              type="text"
              placeholder="搜索关注的作品"
              @keyup.enter="searchList"
            />
            <div class="search_btn" @click="searchList">
              <i class="search_icon"></i>
              <img :src="require('assets/images/idiom.png')" alt="" />
              <img :src="require('assets/images/included_search.png')" alt="" />
            </div>
          </div>
        </div> -->

        <!-- 我的关注 -->
        <div class="choose_box">
          <div
            class="title"
            :class="type == 1 ? 'iActive' : ''"
            @click="
              () => {
                page = 1;
                type = 1;
                getlist(1);
              }
            "
          >
            我的关注({{ total }})
          </div>
          <div
            class="title"
            :class="type == 2 ? 'iActive' : ''"
            @click="
              () => {
                page = 1;
                type = 2;
                getlist(2);
              }
            "
          >
            我的粉丝
          </div>

          <!-- <div class="choose_item_box">
            <div
              @click="clickChoose(index)"
              class="item"
              :class="chooseI == index ? 'isI' : ''"
              v-for="(item, index) in choose_list"
              :key="item"
            >
              {{ item }}
            </div>
          </div> -->
        </div>

        <!-- 作者list -->
        <div class="author_box">
          <div class="author_item" v-for="item in list" :key="item.id">
            <div
              class="info_box"
              @click="
                $router.push({
                  name: 'authorDetails',
                  params: {
                    userId: type == 1 ? item.focusId : item.userId,
                  },
                })
              "
            >
              <div class="img_box">
                <img :src="item.headImg" @error="imgError()" alt="" />
              </div>

              <div class="info">
                <div class="name">{{ item.focusName }}</div>
                <div class="works_sum">作品数量 : {{ item.numberWorks }}</div>
              </div>
            </div>

            <div
              v-if="item.focusType === 0"
              class="focus_btn Focus"
              @click="focusClick(item, 1)"
            >
              关注
            </div>

            <div v-else class="focus_btn" @click="focusClick(item, 2)">
              <img
                :src="
                  item.focusType == 1
                    ? require('assets/images/library/icon_1.png')
                    : require('assets/images/library/icon.png')
                "
                alt=""
              />
              {{ item.focusType == 1 ? "已关注" : "互相关注" }}
            </div>
          </div>
        </div>

        <img
          v-if="list.length == 0"
          class="kong"
          :src="require('assets/images/kong.png')"
          alt=""
        />

        <!-- 分页 -->
        <div class="paging_box" v-if="list.length > 10">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :pager-count="5"
            :current-page="page"
            :page-size="10"
            @current-change="currentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { findFocusList, focus } from "api/library";
export default {
  name: "MyAttention",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      value: "",
      options: [],
      // 搜索关键字
      input: "",
      chooseI: 0,
      // choose_list: ["最近关注", "最早关注"],
      // 关注列表
      list: [],
      // 页数
      page: 1,
      // 总数
      total: 0,
      // 1关注列表 2粉丝列表
      type: 1,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 搜索事件
    searchList() {},

    clickChoose(i) {
      this.chooseI = i;
    },

    // 获取关注列表
    async getlist(type) {
      const data = {
        page: this.page,
        limit: 10,
        type,
      };

      const res = await findFocusList(data);
      if (res.code != 200) {
        this.list = [];
        // this.$message.error(res.message);
        return;
      }
      this.type == 1 ? (this.total = res.data.total) : "";
      // this.list = res.data.data;
      this.list = res.data.data.map((item) => {
        if (!item.headImg) {
          item.headImg = require("assets/images/user.png");
          return item;
        } else {
          return item;
        }
      });
    },

    imgError() {
      return require("assets/images/user.png");
    },

    // 取消关注
    async focusClick(item, type) {
      const data = {
        focusId: this.type == 1 ? item.focusId : item.userId,
        type,
      };

      const res = await focus(data);
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }

      this.getlist(this.type);
    },

    // 分页事件
    currentChange(val) {
      this.page = val;
      this.getlist(this.type);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist(1);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.MyAttention {
  .box {
    // 主体
    .main {
      position: relative;
      overflow-y: auto;
      height: calc(100vh - 50px);
      border-left: 1px solid #f7f7f7;
      flex: 1;
      padding: 0 20px;
      .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }

      // 搜索
      .search_box {
        .search {
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 1px solid #3683f2;

          .search_left {
            .el-select {
              ::v-deep .el-input {
                .el-input__inner {
                  width: 100px;
                  border: 0px;
                }
              }
            }
          }

          .inp {
            height: 26px;
            border-left: 1px solid #e4e4e4;
            padding-left: 20px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-default;
            flex: 1;
          }
          .search_btn {
            height: 40px;
            padding: 0 10px;
            background: #3683f2;
            @include flex-center();
            border-radius: 0 5px 5px 0;
            cursor: pointer;
            > img:first-child {
              width: 52px;
              height: 22px;
              margin: 0 10px;
            }
          }
        }
      }

      // 我的关注
      .choose_box {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .title {
          margin-right: 20px;
          font-size: 14px;
          color: #333333;
          cursor: pointer;
          box-sizing: border-box;
          position: relative;
        }

        .iActive {
          &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%);
            width: 30px;
            height: 3px;
            background-color: #409eff;
          }
        }
        .choose_item_box {
          display: flex;
          align-items: center;
          font-size: 14px;
          .item {
            padding: 9px 14px;
            cursor: pointer;
          }

          .isI {
            color: #4587ff;
            background: #ffffff;
            border: 1px solid #b4cfff;
            border-radius: 16px;
          }
        }
      }

      // 作者list
      .author_box {
        .author_item {
          border-bottom: 1px solid #f5f5f5;
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info_box {
            display: flex;
            cursor: pointer;
            .img_box {
              margin-right: 20px;
              img {
                width: 63px;
                border-radius: 50%;
              }
            }

            .info {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .name {
                font-size: 16px;
              }
              .works_sum {
                font-size: 12px;
              }
            }
          }
          .focus_btn {
            cursor: pointer;
            height: 28px;
            line-height: 28px;
            padding: 0 7px;
            border: 1px solid #666666;
            border-radius: 14px;
            display: flex;
            align-items: center;
            font-size: 12px;
            img {
              width: 12px;
              margin-right: 3px;
            }
          }

          .Focus {
            cursor: pointer;
            background-color: #4587ff;
            color: #fff;
            padding: 0 20px;
            border: 0;
          }
        }
      }

      // 分页
      .paging_box {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }

      // 空
      .kong {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
