var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MyAttention"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.type == 1 ? "我的关注" : "我的粉丝")+" ")]),_c('div',{staticClass:"choose_box"},[_c('div',{staticClass:"title",class:_vm.type == 1 ? 'iActive' : '',on:{"click":function () {
              _vm.page = 1;
              _vm.type = 1;
              _vm.getlist(1);
            }}},[_vm._v(" 我的关注("+_vm._s(_vm.total)+") ")]),_c('div',{staticClass:"title",class:_vm.type == 2 ? 'iActive' : '',on:{"click":function () {
              _vm.page = 1;
              _vm.type = 2;
              _vm.getlist(2);
            }}},[_vm._v(" 我的粉丝 ")])]),_c('div',{staticClass:"author_box"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"author_item"},[_c('div',{staticClass:"info_box",on:{"click":function($event){return _vm.$router.push({
                name: 'authorDetails',
                params: {
                  userId: _vm.type == 1 ? item.focusId : item.userId,
                },
              })}}},[_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":item.headImg,"alt":""},on:{"error":function($event){return _vm.imgError()}}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.focusName))]),_c('div',{staticClass:"works_sum"},[_vm._v("作品数量 : "+_vm._s(item.numberWorks))])])]),(item.focusType === 0)?_c('div',{staticClass:"focus_btn Focus",on:{"click":function($event){return _vm.focusClick(item, 1)}}},[_vm._v(" 关注 ")]):_c('div',{staticClass:"focus_btn",on:{"click":function($event){return _vm.focusClick(item, 2)}}},[_c('img',{attrs:{"src":item.focusType == 1
                  ? require('assets/images/library/icon_1.png')
                  : require('assets/images/library/icon.png'),"alt":""}}),_vm._v(" "+_vm._s(item.focusType == 1 ? "已关注" : "互相关注")+" ")])])}),0),(_vm.list.length == 0)?_c('img',{staticClass:"kong",attrs:{"src":require('assets/images/kong.png'),"alt":""}}):_vm._e(),(_vm.list.length > 10)?_c('div',{staticClass:"paging_box"},[_c('el-pagination',{attrs:{"layout":"total, prev, pager, next, jumper","pager-count":5,"current-page":_vm.page,"page-size":10,"total":_vm.total},on:{"current-change":_vm.currentChange}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }